import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Landing from './pages/Landing';
import Notice from './pages/Notice';
import ListNewsByCategory from './pages/ListNewsByCategory';

// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';

// Tailwind CSS Style Sheet
import './assets/styles/tailwind.css';
import CodeOfEthics from "./pages/CodeOfEthics";
import LastNews from "./pages/LastNews";

const App: React.FC = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Landing}/>
                <Route exact path="/noticia/:id" component={Notice}/>
                <Route exact path="/noticias/:category" component={ListNewsByCategory}/>
                <Route exact path="/codigo-etica" component={CodeOfEthics}/>
                <Route exact path="/ultimas-noticias" component={LastNews}/>
                <Redirect from="*" to="/"/>
            </Switch>
        </Router>
    );
};

export default App;
