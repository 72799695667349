import React from 'react';
import DefaultNavbar from "../components/Navbar";
import Footer from "../components/Footer";
import Header from "../components/sections/Header";

const CodeOfEthics = () => {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar/>
            </div>
            <main>
                <Header/>

                <div className="flex flex-col mx-20 mt-12 text-justify gap-4 pb-20">
                    <p className="text-center mb-4 text-3xl font-bold">CÓDIGO DE ÉTICA</p>
                    <p className="text-xl font-semibold">CÓDIGO DE ÉTICA DEL MEDIO DE COMUNICACIÓN
                        ESCRITO ‘PRENSA EL
                        LIBERTADOR’ DEL CANTÓN SAN JACINTO
                        DE
                        YAGUACHI – PROVINCIA DEL GUAYAS</p>

                    <ol className="ml-2 list-decimal list-inside leading-relaxed space-y-4">
                        <li>El Reglamento a Ley Orgánica de Comunicación en sus arts. 9 y 10 establece una concepción
                            generalizada en torno a las personas naturales o jurídicas que participen en el proceso
                            comunicacional y que deberán considerar ciertas normas para difundir información y
                            opiniones.El
                            Código de Ética de PRENSA EL LIBERTADOR está constituido en los principios actualizados e
                            instaurados por su director fundador de comunicación.En lo periodístico están: El respeto a
                            informar
                            de manera veraz, amplia y oportuna.Investigación de temas de interés público. Difundir,
                            exigir y
                            defender los derechos y deberes colectivos.
                        </li>
                        <li>PRENSA EL LIBERTADOR se rige y da cumplimiento a la Ley Orgánica de
                            Comunicación y su
                            reglamentación vigente, por lo que el trabajo periodístico lo realiza con toda la
                            responsabilidad a
                            la ciudadanía. El Código de Ética garantiza productos alineados a las leyes y a la buena fe
                            de
                            esta
                            profesión.Entre los valores y principios básicos para el desempeño, constan: Pluralidad,
                            equilibrio,
                            credibilidad,- imparcialidad, honestidad, tratamiento de la información y respeto a la línea
                            editorial.
                        </li>
                        <li>PRENSA EL LIBERTADOR, con cobertura regional y pluralismo
                            ideológico, en su Código de Ética
                            cuenta
                            con las normas referidas a la dignidad humana, a las relaciones con los grupos de atención
                            prioritaria, a las concernientes al ejercicio profesional y a las relacionadas con las
                            prácticas
                            de
                            los medios de comunicación.Igualmente tiene presentes los principios establecidos en la
                            citada
                            Ley
                            que dicen relación con la acción afirmativa, la democratización de la comunicación e
                            información
                            de
                            participación ciudadana, de interculturalidad y plurinacionalidad, del interés superior de
                            niñas,
                            niños y adolescentes, así como el principio de transparencia.También incluye los derechos a
                            la
                            comunicación, tales como el derecho a la libertad de expresión y opinión, a la prohibición
                            de
                            censura previa, toma en cuenta la responsabilidad ulterior y solidaria de acuerdo con lo que
                            establece la Constitución y las Leyes.Reconoce el derecho de todas las personas a recibir
                            información de relevancia pública y veraz, así como el derecho a la rectificación y a la
                            réplica.
                        </li>
                    </ol>

                    <p className="text-xl font-semibold">POLÍTICA EDITORIAL E INFORMATIVA DE PRENSA EL
                        LIBERTADOR
                    </p>


                    <ol className="ml-2 list-decimal list-inside leading-relaxed space-y-4">
                        <li>
                            PRENSA EL LIBERTADOR, es un periódico de información general, con
                            vocación democrática,
                            pluralista, y comprometido con los principios y normas establecidos en la Constitución de la
                            República y leyes vigentes.
                        </li>
                        <li>
                            PRENSA EL LIBERTADOR presenta quincenalmente una información veraz,
                            contrastada, precisa y
                            contextualizada lo más completa posible, interesante, actual y de alta calidad, de manera
                            que
                            ayude
                            al lector a entender la realidad y a formarse su propio criterio.
                        </li>
                        <li>
                            PRENSA EL LIBERTADOR considera que la independencia y la no
                            manipulación de las noticias son
                            garantía para los derechos de los lectores, razón de ser del trabajo comunicacional.
                        </li>
                        <li>
                            PRENSA EL LIBERTADOR rechaza cualquier presión de personas,
                            partidos políticos, grupos
                            económicos,
                            religiosos o ideológicos que traten de poner la información al servicio de sus intereses.
                        </li>
                        <li>
                            PRENSA EL LIBERTADOR, mantiene la información y la opinión
                            claramente diferenciadas entre sí,
                            así
                            como otros tipos de contenidos. Para ello se toma en cuenta el código establecido en la Ley
                            Orgánica
                            de Comunicación.
                        </li>
                        <li>
                            PRENSA EL LIBERTADOR es cuidadoso y prudente con las informaciones
                            relativas a suicidios,
                            asesinatos, delincuencia, violaciones y afines. En estos casos, se omitirá el nombre de los
                            actores,
                            a no ser que haya sentencia ejecutoriada.Solamente podrán utilizarse las iniciales o datos
                            genéricos
                            (edad, profesión, nacionalidad), siempre que no la identifiquen. También se emplearán
                            iniciales
                            cuando los detenidos por la policía o los acusados formalmente de un delito sean menores de
                            edad. El
                            hecho de que una información haya sido facilitada por una fuente con la petición de que no
                            sea
                            difundida, no impide su publicación si se obtiene honestamente por otros medios.
                        </li>
                        <li>
                            PRENSA EL LIBERTADOR no da cabida a contenidos discriminatorios y
                            violentos de ni ninguna
                            clase,
                            en los términos establecidos en la Ley Orgánica de Comunicación.
                        </li>
                    </ol>

                    <p className="text-xl font-semibold">
                        RESPONSABILIDAD PROFESIONAL
                    </p>

                    <ol className="ml-2 list-decimal list-inside leading-relaxed space-y-4">
                        <li>
                            El derecho a la información es sobre todo del lector, no del
                            periodista. Esto significa que los
                            redactores del periódico no deben privar a una persona o a una institución de que acceda a
                            la
                            publicación de una noticia. Las columnas de PRENSA EL LIBERTADOR no están para que el
                            redactor
                            desahogue sus odios o antipatías, ni sus simpatías o compromisos personales por justificados
                            que
                            sean.
                        </li>
                        <li>
                            PRENSA EL LIBERTADOR enmendará sin dilaciones los errores cometidos
                            en sus páginas, esta tarea
                            la
                            deberá cumplir especialmente los responsables de cada área informativa. Se cuidará del
                            cumplimiento
                            de lo establecido en la Ley Orgánica de Comunicación, respecto al derecho a la rectificación
                            y
                            el
                            derecho a la réplica.
                        </li>
                        <li>
                            PRENSA EL LIBERTADOR, tiene la obligación a través de su redactor
                            de releer y corregir sus
                            originales, sea que las escriba en Redacción o las remita por medios electrónicos. La
                            primera
                            responsabilidad de las erratas y equivocaciones es de quien redacta el texto, y en segundo
                            lugar,
                            del editor encargado de revisarlo.
                        </li>
                    </ol>

                    <p className="text-xl font-semibold">
                        TRATAMIENTO DE LAS NOTICIAS
                    </p>

                    <ol className="ml-2 list-decimal list-inside leading-relaxed space-y-4">
                        <li>
                            PRENSA EL LIBERTADOR transmite a los lectores noticias verificadas,
                            contrastadas, precisas y
                            contextualizadas y se abstiene de incluir en ellas sus opiniones personales. Cuando un hecho
                            no
                            haya
                            sido verificado suficientemente, el redactor evitará en las noticias expresiones como ‘al
                            parecer’,
                            ‘podría’, ‘no se descarta’ o similares.
                        </li>
                        <li>
                            Para PRENSA EL LIBERTADOR, los rumores no son noticia, las fuentes,
                            las informaciones de que se
                            disponga, solo pueden ser obtenidas por dos vías: su presencia en el lugar de los hechos o
                            la
                            narración por una tercera persona. El lector tiene derecho a conocer cuál de las dos
                            posibilidades
                            corresponde con la noticia que está leyendo. Para ello, se citará siempre una fuente cuando
                            el
                            periodista no haya estado presente en la acción que transmite. Si la información procede de
                            una
                            sola
                            persona, se hablará de ‘fuente’ en singular.
                        </li>
                    </ol>

                    <p className="text-xl font-semibold">
                        DISEÑO Y DIAGRAMACIÓN DE NOTICIAS
                    </p>

                    <ol className="ml-2 list-decimal list-inside leading-relaxed space-y-4">
                        <li>
                            La actividad de Prensa ‘El Libertador’ se da desde el 12 de
                            octubre del año 2000, teniendo ya
                            casi 24 años en circulación como medio de comunicación escrito con operación quincenal. El
                            despliegue actual es de 5000 ejemplares en cada edición. La presentación es tamaño tabloide,
                            con
                            portada full color impreso en papel couche, los interiores en papel bond, en blanco y negro
                            y a
                            color. Se usará las fuentes Impact para título y texto Times New Roman. 2.El personal de
                            PRENSA
                            EL
                            LIBERTADOR, a través de éste documento se compromete a cumplir con lo dispuesto en el
                            presente
                            Código de Ética, con la finalidad de cumplir sus labores en apego al mismo para bien de los
                            lectores
                            y ciudadanía en general.
                        </li>
                    </ol>

                    <p className="text-center mt-10">
                        Lcdo. Manuel Chunga Valle
                    </p>
                    <p className="text-center">
                        DIRECTOR
                    </p>
                </div>

            </main>
            <Footer/>
        </>
    );
}

export default CodeOfEthics