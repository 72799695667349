import React from 'react';
import NoticeItem from './NoticeItem';
import News from "../../types/News";

interface SectionNoticeProps {
    news: News[];
}

const SectionNotice: React.FC<SectionNoticeProps> = ({ news }) => {
    return (
        <div className="flex flex-wrap items-center mt-32">
            {news.map(notice => (
                <NoticeItem key={notice.newsId} notice={notice} />
            ))}
        </div>
    );
};

export default SectionNotice;
