import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="relative bg-gray-100 pb-6 py-16">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex flex-wrap text-center lg:text-left">
                    <div className="w-full lg:w-6/12 px-4 mb-10 text-center">
                        <h2 className="text-gray-900 text-lg font-semibold mb-4">CONTACTO</h2>
                        <p className="text-gray-700">
                            <i className="fas fa-phone-alt text-lg mr-2"></i>
                            04-2020404 – 0989789591 – 0992953845
                        </p>
                        <p className="text-gray-700 mt-2">
                            <i className="fas fa-map-marker-alt text-lg mr-2"></i>
                            Oficina Central: Cantón Yaguachi, Ecuador, Guayas
                        </p>
                        <p className="text-gray-700 mt-2">
                            <i className="fas fa-envelope text-lg mr-2"></i>
                            chungava@hotmail.com - manuelchunga26@live.com
                        </p>
                    </div>
                    <div className="w-full lg:w-6/12 px-4 text-center">
                        <h2 className="text-gray-900 text-lg font-semibold mb-4">SECCIONES</h2>
                        <ul className="list-unstyled">
                            <li>
                                <Link
                                    to="/codigo-etica"
                                    className="text-gray-700 hover:text-gray-900 block py-2"
                                >
                                    Código de ética
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/ultimas-noticias"
                                    className="text-gray-700 hover:text-gray-900 block py-2"
                                >
                                    Últimas noticias
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="my-6 border-gray-300"/>
                <div className="flex flex-wrap items-center justify-center">
                    <div className="w-full text-center">
                        <div className="text-sm text-gray-700 font-medium py-1">
                            Copyright © {new Date().getFullYear()} Prensa el Libertador.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
