import React from 'react';
import Header from '../components/sections/Header';
import Main from '../components/sections/Main';
import DefaultNavbar from "../components/Navbar";
import Footer from "../components/Footer";
import ReaderMailbox from "../components/sections/ReaderMailbox";

export default function Landing() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar/>
            </div>
            <main>
                <Header/>
                <Main/>
                {/*<AdditionalContent />*/}
                <ReaderMailbox/>
            </main>
            <Footer/>
        </>
    );
}
