import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import SectionNotice from '../components/news/SectionNotice';
import DefaultNavbar from '../components/Navbar';
import Header from '../components/news/Header';
import Footer from '../components/Footer';
import {getNewsByCategory} from '../api';
import News from "../types/News";

interface Params {
    category?: string;
}

class ScrollToTopOnMount extends React.Component {
    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }
}

export default function ListNewsByCategory() {
    const {category} = useParams<Params>();
    const [news, setNews] = useState<News[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const fetchedNews = await getNewsByCategory(category || '');
                setNews(fetchedNews);
            } catch (error) {
                setError('Error fetching news');
                console.error('Error fetching news:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, [category]);

    let newsInSection: News[] = [];
    let totalInSection = 0;
    let actualSetSection = false;

    return (
        <>
            <ScrollToTopOnMount/>
            <div className="absolute w-full z-20">
                <DefaultNavbar/>
            </div>
            <main>
                <Header/>
                <section className="relative py-16 bg-gray-100">
                    <div className="flex flex-wrap items-center">
                        {loading ? (
                            <div className="flex w-full flex-wrap justify-center">
                                <div className="w-full px-12 flex flex-col items-center">
                                    <h6 className="text-gray-500 text-lg">Cargando noticias...</h6>
                                </div>
                            </div>
                        ) : error ? (
                            <div className="flex flex-wrap justify-center">
                                <div className="w-full px-12 flex flex-col items-center">
                                    <h6 className="text-red-500 text-lg">{error}</h6>
                                </div>
                            </div>
                        ) : news.length > 0 ? (
                            news.map((notice) => {
                                newsInSection.push(notice);
                                totalInSection++;
                                actualSetSection = false;
                                if (newsInSection.length === 3) {
                                    const toSend = [...newsInSection];
                                    newsInSection = [];
                                    actualSetSection = true;
                                    return <SectionNotice key={totalInSection} news={toSend}/>;
                                }
                                if (totalInSection === news.length && !actualSetSection) {
                                    return <SectionNotice key={totalInSection} news={newsInSection}/>;
                                }
                            })
                        ) : (
                            <div className="flex w-full justify-center">
                                <div className="w-full px-12 flex flex-col items-center">
                                    <h6 className="text-gray-500 text-lg">Aún no hay noticias de esta categoría</h6>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    );
}
