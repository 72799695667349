import React, {useEffect, useState} from 'react';
import StatusCard from '../system-design/StatusCard';
import SectionNotice from '../news/SectionNotice';
import News from "../../types/News";
import {getLatestNews} from "../../api";

const Main: React.FC = () => {
    const [news, setNews] = useState<News[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const limit = 3;

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const fetchedNews = await getLatestNews(limit);
                setNews(fetchedNews);
            } catch (error) {
                setError('Error fetching latest news');
                console.error('Error fetching latest news:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, [limit]);

    return (
        <section className="pb-20 bg-gray-100 -mt-32">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex flex-wrap relative z-50">
                    <StatusCard color="lightBlue" title="Director" image="foto_director.png">
                        Lcdo. Manuel Chunga Valle
                    </StatusCard>

                    <StatusCard color="teal" title="Editora" image="foto_editora.png">
                        Lcda. Alicia Bayas Bayas
                    </StatusCard>

                    <StatusCard color="red" title="Editor" image="foto_gerente.png">
                        Manuel Chunga Armijos
                    </StatusCard>

                </div>

                <div className="mt-20">
                    {loading ? (
                        <div className="flex w-full justify-center">
                            <p>Cargando noticias...</p>
                        </div>
                    ) : error ? (
                        <div className="flex justify-center">
                            <p>{error}</p>
                        </div>
                    ) : (
                        <SectionNotice news={news}/>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Main;
