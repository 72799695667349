import React from 'react';

const colors = {
    blueGray: 'bg-blue-gray-500',
    gray: 'bg-gray-500',
    brown: 'bg-brown-500',
    deepOrange: 'bg-deep-orange-500',
    orange: 'bg-orange-500',
    amber: 'bg-amber-500',
    yellow: 'bg-yellow-600',
    lime: 'bg-lime-500',
    lightGreen: 'bg-light-green-500',
    green: 'bg-green-500',
    teal: 'bg-teal-500',
    cyan: 'bg-cyan-500',
    lightBlue: 'bg-light-blue-500',
    blue: 'bg-blue-500',
    indigo: 'bg-indigo-500',
    deepPurple: 'bg-deep-purple-500',
    purple: 'bg-purple-500',
    pink: 'bg-pink-500',
    red: 'bg-red-500',
};

interface StatusCardProps {
    color: keyof typeof colors;
    title: string;
    children: React.ReactNode;
    image?: string;
    imageAlt?: string;
}

const StatusCard: React.FC<StatusCardProps> = ({ color, title, children, image, imageAlt }) => {
    return (
        <div className="w-full md:w-4/12 px-8 flex justify-center text-center">
            <div className="bg-white rounded-lg shadow-lg">
                <div className="p-6">
                    {image && <img alt={imageAlt || title} className="w-full rounded-lg shadow-lg mb-4" src={image} />}
                    <h6 className="text-gray-800 text-xl font-semibold mt-4">{title}</h6>
                    <p className="text-blue-gray-500 mt-2">{children}</p>
                </div>
            </div>
        </div>
    );
};

export default StatusCard;
