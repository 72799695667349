import React from 'react';
import { useParams } from 'react-router-dom';

interface Params {
    category?: string;
}

const Header: React.FC = () => {
    const { category } = useParams<Params>();

    const getText = () => {
        switch (category) {
            case 'classified':
                return 'Clasificados';
            case 'family':
                return 'Familia, salud y vida';
            case 'politic':
                return 'Política';
            case 'editorial':
                return 'Editorial';
            case 'sports':
                return 'Deportes';
            case 'educational':
                return 'Educativo, cultural y artístico';
            case 'context':
                return 'Contexto nacional, regional y local';
            default:
                return 'Noticias';
        }
    };

    return (
        <section className="relative block h-[500px]">
            <div className="bg-profile-background bg-cover bg-center absolute top-0 w-full h-full" />
            <div className="container max-w-8xl relative mx-auto pt-48">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                        <h2 className="text-white text-4xl font-semibold">{getText()}</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;
