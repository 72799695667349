import axios from 'axios';

// Base URL de tu API Gateway
const API_BASE_URL = 'https://uqd3raoue8.execute-api.us-east-1.amazonaws.com/prod';

const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getLatestNews = async (limit: number) => {
    try {
        const response = await api.get('/latest-news', {
            params: {limit}
        });
        console.log(response)
        return response.data;
    } catch (error) {
        console.error('Error fetching latest news:', error);
        throw error;
    }
};

export const getNews = async (newsId: string) => {
    try {
        const response = await api.get(`/news/${newsId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching news with ID ${newsId}:`, error);
        throw error;
    }
};

export const getNewsByEdition = async (editionId: string) => {
    try {
        const response = await api.get(`/news/edition/${editionId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching news by edition ID ${editionId}:`, error);
        throw error;
    }
};

export const getNewsByCategory = async (category: string) => {
    try {
        const response = await api.get(`/news/category/${category}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching news by category ${category}:`, error);
        throw error;
    }
};

export const getEditions = async () => {
    try {
        const response = await api.get('/editions');
        return response.data;
    } catch (error) {
        console.error('Error fetching editions:', error);
        throw error;
    }
};

export const getEdition = async (editionId: string) => {
    try {
        const response = await api.get(`/editions/${editionId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching edition with ID ${editionId}:`, error);
        throw error;
    }
};

export const sendReaderMessage = async (data: { name: string; email: string; message: string }) => {
    try {
        const response = await api.post('/send-reader-message', data);
        return response.data;
    } catch (error) {
        console.error('Error sending reader message:', error);
        throw error;
    }
};
