import React, {useState} from 'react';
import {sendReaderMessage} from "../../api";

const ReaderMailbox = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const validateEmail = (email: string) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!name || !email || !message) {
            setError('Todos los campos son obligatorios.');
            setSuccess('');
            return;
        }
        if (!validateEmail(email)) {
            setError('Por favor, ingrese un correo electrónico válido.');
            setSuccess('');
            return;
        }
        try {
            await sendReaderMessage({name, email, message});
            setSuccess('Mensaje enviado con éxito.');
            setError('');
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            setError('Error al enviar el mensaje. Por favor, intente nuevamente.');
            setSuccess('');
        }
    };

    return (
        <div className="flex flex-col items-center my-24 px-4">
            <h3 className="text-gray-800 text-3xl font-semibold mb-8">Buzón del lector</h3>
            <form onSubmit={handleSubmit} className="w-full lg:w-8/12 space-y-4">
                <input
                    type="text"
                    placeholder="Nombre completo"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="border border-gray-300 p-3 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-lightBlue-500"
                />
                <input
                    type="email"
                    placeholder="Correo Electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-gray-300 p-3 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-lightBlue-500"
                />
                <textarea
                    placeholder="Mensaje"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="border border-gray-300 p-3 w-full h-32 rounded-lg focus:outline-none focus:ring-2 focus:ring-lightBlue-500"
                />
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}
                <button
                    type="submit"
                    className="bg-black text-white p-3 rounded-lg shadow-md"
                >
                    Enviar
                </button>
            </form>
        </div>
    );
}

export default ReaderMailbox