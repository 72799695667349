import React, {useEffect, useState} from 'react';
import Header from '../components/news/Header';
import Content from '../components/news/Content';
import {useParams} from 'react-router-dom';
import DefaultNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import {getNews} from "../api";
import News from "../types/News";

interface Params {
    id: string;
}

class ScrollToTopOnMount extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }
}

export default function Notice() {
    const {id} = useParams<Params>();
    const [notice, setNotice] = useState<News | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getNews(id);
                setNotice(data);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchData();
    }, [id]);

    if (!notice) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <ScrollToTopOnMount/>
            <div className="absolute w-full z-20">
                <DefaultNavbar/>
            </div>
            <main>
                <Header/>
                <Content notice={notice}/>
            </main>
            <Footer/>
        </>
    );
}
