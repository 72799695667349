import React, {useState} from 'react';

interface Notice {
    title: string;
    location: string;
    content: string;

    [key: string]: any;
}

interface ContentProps {
    notice: Notice;
}

const Content: React.FC<ContentProps> = ({notice}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <section className="relative py-16 bg-gray-100">
            <div className="container max-w-8xl px-4 mx-auto">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
                    <div className="px-6">
                        <div className="text-center my-8">
                            <h3 className="text-gray-800 text-3xl font-semibold mb-6">{notice.title}</h3>
                            {notice.location && (<div
                                className="mt-0 mb-2 text-gray-700 font-medium flex items-center justify-center gap-2">
                                <i className="fas fa-map-marker-alt text-xl"></i>
                                {notice.location}
                            </div>)}
                        </div>

                        <div className="flex justify-center">
                            <img alt={notice.title} className="mx-20 rounded-t-lg" src={notice.imageUrl}/>
                        </div>

                        <div className="mb-10 py-2 flex w-full justify-center mt-6">
                            <div className="w-full lg:w-9/12 px-4 flex flex-col items-center">
                                <p className="text-gray-800 mt-2 text-lg text-justify">
                                    {showMore ? notice.content : notice.content.substring(0, 300).concat('...')}
                                </p>
                                <button
                                    className="text-lightBlue-500 mt-4"
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    {showMore ? 'Ver menos' : 'Ver más'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Content;
