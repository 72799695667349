import React from 'react';
import {useHistory} from 'react-router-dom';
import News from '../../types/News';

interface NewItemProps {
    notice: News;
}

const NoticeItem: React.FC<NewItemProps> = ({notice}) => {
    const noticeUrl = '/noticia/' + notice.newsId;
    const history = useHistory();

    return (
        <div
            className="w-full md:w-4/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0"
            role="button"
            tabIndex={0}
            onClick={() => {
                history.push(noticeUrl);
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    history.push(noticeUrl);
                }
            }}
        >
            <div className="bg-white rounded-lg shadow-lg">
                <div className="h-64 overflow-hidden">
                    <img
                        alt={notice.title}
                        className="w-full h-full object-cover object-center"
                        src={notice.imageUrl}
                    />
                </div>
                <div className="p-6">
                    <h6 className="text-gray-800 text-xl font-semibold">{notice.title}</h6>
                    <p className="text-blue-gray-500 mt-2">
                        {notice.content.substring(0, 150).concat('...')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NoticeItem;
