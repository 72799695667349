import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

    return (
        <header className="text-white">
            <div className="container mx-auto px-4 py-4 my-4 flex justify-between items-center">
                <Link to="/" className="text-4xl font-bold">
                    El Libertador
                </Link>
                <button
                    className="text-white lg:hidden"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
                        />
                    </svg>
                </button>
                <nav
                    className={`lg:flex lg:items-center lg:w-auto w-full ${isOpen ? 'block' : 'hidden'}`}
                >
                    <ul className="lg:flex lg:space-x-6">
                        <li>
                            <Link to="/codigo-etica"
                                  className="block py-2 px-4 rounded transform transition duration-300 ease-in-out hover:scale-105 hover:bg-gray-700 hover:text-white">
                                Código de Ética
                            </Link>
                        </li>

                        <li className="relative">
                            <button
                                className="flex items-center py-2 px-4 rounded transform transition duration-300 ease-in-out hover:scale-105 hover:bg-gray-700 hover:text-white"
                                onClick={() => setIsCategoriesOpen(!isCategoriesOpen)}
                            >
                                Categorías
                                <svg
                                    className="w-4 h-4 ml-1"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </button>
                            <ul className={`absolute right-0 mt-2 w-48 bg-gray-800 bg-opacity-75 text-gray-100 rounded-lg shadow-lg ${isCategoriesOpen ? 'block' : 'hidden'}`}>
                                <li>
                                    <Link
                                        to="/noticias/politica"
                                        className="block py-2 px-4 hover:bg-gray-700 rounded"
                                    >
                                        Política
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/noticias/sociedad"
                                        className="block py-2 px-4 hover:bg-gray-700 rounded"
                                    >
                                        Sociedad
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/noticias/cultural"
                                        className="block py-2 px-4 hover:bg-gray-700 rounded"
                                    >
                                        Cultural
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/noticias/mas"
                                        className="block py-2 px-4 hover:bg-gray-700 rounded"
                                    >
                                        Más
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
